<script>
export default {}
</script>

<template>
  <v-banner two-line color="red lighten-5" transition="hide-on-leave">
    <v-avatar slot="icon" color="error" size="40">
      <v-icon color="white"> mdi-thumb-down </v-icon>
    </v-avatar>
    <div class="red--text font-weight-bold mb-2">We are sorry!</div>
    <div class="red--text font-weight-medium mb-2">Your application was not accepted…</div>
    <template v-slot:actions="{ dismiss }" align="center"> <v-spacer /><v-btn medium class="error" @click="dismiss">OK</v-btn><v-spacer /></template>
  </v-banner>
</template>
